export const SET_USER = 'SET_USER';
export const SET_USERS = 'SET_USERS';
export const DELETE_USER = 'DELETE_USER';
export const SET_TABVIEW = 'SET_TABVIEW';
export const UPDATE_PREVIEW = 'UPDATE_PREVIEW';
export const OPEN_PREVIEW = 'OPEN_PREVIEW';
export const RESET_PREVIEW = 'RESET_PREVIEW';
export const SET_DETAIL_PAGE_IMAGES_PENDING = 'SET_DETAIL_PAGE_IMAGES_PENDING';
export const SET_BANNER_IMAGE_PENDING = 'SET_BANNER_IMAGE_PENDING';
export const SET_THUMBNAIL_IMAGE_PENDING = 'SET_THUMBNAIL_IMAGE_PENDING';
export const SET_VIDEO_PENDING = 'SET_VIDEO_PENDING';
