import React from 'react';
import './NotFound.sass';

const NotFound = () => {
    return (
        <div className='NotFound'>
            404 page not found!!!
        </div>
    );
};

export default NotFound;